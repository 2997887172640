<template>
  <div>
    <div>
      <navbar />
    </div>
    <div class="middle">
      <div class="content">
        <div class="content-left">
          <h3>Account detail</h3>
          <ul class="setting">
            <li
              :class="item.isActive ? 'setting-list active' : 'setting-list'"
              v-for="(item, index) in settingList"
              :key="index"
              @click="routerClick(item, index)"
            >
              <div>{{ item.name }}</div>
              <svg-icon class="icon1" icon="arrow-right"></svg-icon>
            </li>
          </ul>
        </div>
        <router-view />
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
import Navbar from "../.././layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import { getCurrentInstance, reactive, nextTick, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
const { $t } = getCurrentInstance().proxy;
const router = useRouter();
const route = useRoute();
const settingList = reactive([
  {
    name: "Account Settings",
    router: "/pageSetting/account",
    isActive: false,
  },
  {
    name: "Top Up Record",
    router: "/pageSetting/recharge",
    isActive: false,
  },
  {
    name: "Consumption Record",
    router: "/pageSetting/consumption",
    isActive: false,
  },
]);
//子路由跳转
const routerClick = (item, index) => {
  settingList.forEach((info) => {
    info.isActive = false;
  });
  settingList[index].isActive = true;
  nextTick(() => {
    router.push({
      path: item.router,
    });
  });
};
const isActive = () => {
  let path = route.path;
  settingList.forEach((item, index) => {
    item.isActive = false;
    if (item.router == path) {
      settingList[index].isActive = true;
    }
  });
};

const goPage = () => {
  router.push("/onlineExpert");
};
onMounted(() => {
  isActive();
});
</script>

<style lang="scss" scoped>
.middle {
  width: 100%;
  background: linear-gradient(180deg, lightblue, #fff, #fff);
  height: 600px;

  .content {
    width: 900px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    .content-left {
      width: 320px;
      height: 400px;
      background-color: rgba(0, 0, 0, 0.3);

      h3 {
        line-height: 40px;
        color: #edaaaa;
        margin-left: 15px;
      }

      .setting {
        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          padding: 0 13px;
          color: #ffff;
          font-size: 15px;
          line-height: 50px;
          border-bottom: 1px solid #5a5e5f;
          cursor: pointer;

          .icon1 {
            margin-top: 25px;
          }
        }
      }

      .setting-list:hover {
        background-color: #6f6a6a;
      }
    }
  }
}
.active {
  background-color: #6f6a6a;
}
:deep(.svg-icon) {
  margin-top: 19px !important;
}
</style>
